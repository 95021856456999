import * as React from "react";
import { Col } from "reactstrap";

export default class Rewards extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return <Col md={12}><div style={{color: 'white'}}>This is Rewards page</div></Col>;
  }
}
