/* eslint-disable no-return-assign */
import React from "react";
import NotificationSystem from "rc-notification";
import { BasicNotification } from '../../shared/components/Notification';

let notificationRU = null;

const showNotification = (title, message, color = "primary") => {
  notificationRU.notice({
    content: (
      <BasicNotification color={color} title={title} message={message} />
    ),
    duration: 5,
    closable: true,
    style: { top: 0, left: "calc(100vw - 100%)" },
    className: 'right-up'
  });
};

export default WrappedComponent => {
  return class extends React.Component {
    componentDidMount() {
      NotificationSystem.newInstance({}, n => (notificationRU = n));
    }

    componentWillUnmount() {
      //notificationRU.destroy();
    }

    render() {
      return (
        <WrappedComponent showNotification={showNotification} {...this.props} />
      );
    }
  };
};
