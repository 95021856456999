import { number } from "prop-types";

export default {
  ItemTypeLookup: {
    0: "Resource",
    1: "Planet",
    2: "Temple",
    3: "SuperPower",
    4: "Constellation",
    5: "Element",
    6: "Satellite",
    7: "Jovian",
    8: "Cosmic Action",
    9: "Space Junk",
    10: "Space Orbs",
    11: "Miracle",
    12: "Inhabitant",
    13: "Dimension",
    14: "Cluster",
    15: "Artifact",
    16: "Intent Card",
  },
  CurrencyLookup: {
    0: "Energy",
    1: "Spirit",
    2: "Dogma",
    3: "USD",
  },

  GetItemTypeTable: (itemType) => {
    if (itemType == undefined) return null;
    else if (itemType == "15") return "Artifact";
    else if (
      itemType == "16" ||
      itemType == "11" ||
      itemType == "8" ||
      itemType == "9" ||
      itemType == "4" ||
      itemType == "7"
    )
      return "InventoryItem";
    else return null;
    // if(this.ItemTypeLookup[parseInt(itemType)] == 'Artifact'){
    //   return 'Artifact';
    // }
    // else if(this.ItemTypeLookup[parseInt(itemType)] == 'Intent Card'){
    //   return 'InventoryItem';
    // }
    // else
    //   return null;
  },

  ShowAmountField: (itemType) => {
    if (
      itemType == "15" ||
      itemType == "16" ||
      itemType == "11" ||
      itemType == "8" ||
      itemType == "9" ||
      itemType == "4" ||
      itemType == "7"
    )
      return false;
    return true;
    // if(this.ItemTypeLookup[parseInt(itemType)] == 'Artifact'){
    //   return 'Artifact';
    // }
    // else if(this.ItemTypeLookup[parseInt(itemType)] == 'Intent Card'){
    //   return 'InventoryItem';
    // }
    // else
    //   return null;
  },
};
