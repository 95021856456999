import * as React from "react";
import {
  TextField,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  CircularProgress  
} from "@material-ui/core";
import { Button } from "reactstrap";
import server from "../../server";
import withNotifications from "../notification";

class EditPlayerCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      ...this.props.player
    };
  }

  renderTextField = (label, field) => (
    <TextField
      label={label}
      value={this.state[field]}
      onChange={e => this.setState({ [field]: e.target.value })}
      fullWidth
      style={{ marginBottom: 15 }}
    />
  );

  onSave = () => {
    this.setState({ isLoading: true }, () => {
      server
        .post("players/update", {
          oldData: this.props.player,
          newData: this.state
        })
        .then(response => {
          this.props.showNotification(
            "",
            "Data updated successfully",
            "success"
          );
          this.props.onClose(true);
        })
        .catch(error => {
          console.error(error);
          this.props.showNotification("", "An error occured");
        })
        .finally(() => {
          this.setState({ isLoading: false });
        });
    });
  };

  render() {
    return (
      <Card>
        <CardHeader title="Edit Player"/>
        <CardContent>
          {this.renderTextField("PlayerName", "PlayerName")}
          {this.renderTextField("Title1", "PlayerTitle1")}
          {this.renderTextField("Title2", "PlayerTitle2")}
          {this.renderTextField("Energy", "Currency0")}
          {this.renderTextField("Spirit", "Currency1")}
          {this.renderTextField("Dogma", "Currency2")}
          {this.renderTextField("Email", "GenericUserCredentialEmail")}
        </CardContent>
        <CardActions style={{display: 'flex'}}>
          <div style={{flex: 1}}/>
          {this.state.isLoading && <CircularProgress/>}
          <Button disabled={this.state.isLoading} onClick={() => this.props.onClose(false)}>Close</Button>
          <Button disabled={this.state.isLoading} color="primary" onClick={this.onSave}>
            Save
          </Button>
        </CardActions>
      </Card>
    );
  }
}

export default withNotifications(EditPlayerCard);
