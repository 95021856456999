import * as React from "react";
import MaterialTable from "material-table";
import { Card, CardBody } from "reactstrap";

console.log("===========================================");
console.log(MaterialTable);
console.log("===========================================");

export default props => (
  <MaterialTable
    components={{
      Container: containerProps => (
        <Card style={{ paddingBottom: 0 }}>
          <CardBody style={{ padding: 0 }}>{containerProps.children}</CardBody>
        </Card>
      ),
      ...props.components
    }}
    {...props}
    options={{
      emptyRowsWhenPaging: false,      
      pageSize: 20,
      pageSizeOptions: [20, 100, 200],
      maxBodyHeight: "75vh",
      search: true,
      filtering: true,
      actionsColumnIndex: -1,
      headerStyle:{
        backgroundColor: '#232329',
      },
      rowStyle: {
        backgroundColor: '#2f2f37'
      },
      ...props.options
    }}
  />
);
