import axios from 'axios';

const server = axios.create({
  baseURL:
    (process.env.NODE_ENV || 'dev') === 'production'
      ? 'https://api.metagalaxy.gg'
      : 'http://localhost:4000',
  timeout: 100000,
});

server.interceptors.response.use(
  (response) => {
    // intercept the global error
    return response;
  },
  (error) => {
    let originalRequest = error.config;
    if (
      error.response &&
      error.response.status === 403 &&
      !originalRequest._retry
    ) {
      // if the error is 401 and hasent already been retried
      originalRequest._retry = true; // now it can be retried

      window.location.href = '/';
    }

    // Do something with response error
    return Promise.reject(error);
  }
);

export default server;
