import { Icon, withTheme, Tooltip } from "@material-ui/core";
import * as React from "react";
import { Col } from "reactstrap";
import server from "../../../../server";
import withNotifications from "../../../notification";
import Table from "../../../../shared/components/table";
import EditPricingDialog from "./edit-pricing-dialog";
import Data from "./data";
import { toImageUrl } from "../../../../util/image-uploader";
import * as consts from "../../../../consts";
import AttachMoney from "@material-ui/icons/AttachMoney";

const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;
class EconomyPricing extends React.Component {
  constructor(props) {
    super(props);
    <server className="defaults bas"></server>;

    this.state = {
      isLoading: false,
      columns: [
        {
          title: "Pricing Info",
          render: (rowData) => {
            const src = rowData.Icon ? toImageUrl(rowData.Icon) : Ava;

            return (
              <div style={{ display: "flex", alignItems: "center" }}>
                <img style={{ width: 40, height: 40 }} src={src} />
                <div style={{ flex: 1, margin: "0px 16px 0px 16px" }}>
                  <div
                    style={{
                      fontWeight: 500,
                      fontSize: 14,
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                  >
                    {rowData.ItemName}
                  </div>
                  <div
                    style={{
                      color: this.props.theme.palette.grey[500],
                      fontSize: 11,
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      maxWidth: 200,
                    }}
                  >
                    <Tooltip title={rowData.ItemDescription}>
                      <span>{rowData.ItemDescription}</span>
                    </Tooltip>
                  </div>
                </div>
                <div style={{ textAlign: "right" }}>
                  <div
                    style={{
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                  >
                    <span
                      style={{
                        color: this.props.theme.palette.grey[500],
                        fontSize: 11,
                      }}
                    >
                      Id:{" "}
                    </span>
                    <span>{rowData.Id}</span>
                  </div>
                  <div
                    style={{
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                  >
                    <span
                      style={{
                        color: this.props.theme.palette.grey[500],
                        fontSize: 11,
                      }}
                    >
                      Item Id:
                    </span>
                    <span>{rowData.ItemId}</span>
                  </div>
                </div>
              </div>
            );
          },
        },
        // {
        //   title: "Icon",
        //   field: "Icon",
        //   filtering: false,
        //   render: (rowData) => {
        //     const src = rowData.Icon ? toImageUrl(rowData.Icon) : Ava;
        //     return <img style={{ width: 40, height: 40 }} src={src} />;
        //   },
        // },
        // { title: "Id", field: "Id", filtering: false },
        // {
        //   title: "ItemId",
        //   field: "ItemId",
        //   filtering: false,
        // },
        // { title: "Name", field: "ItemName", filtering: false },
        {
          title: "Type",
          field: "ItemType",
          lookup: Data.ItemTypeLookup,
        },
        // { title: "Description", field: "ItemDescription", filtering: false },
        {
          title: "Price",
          field: "Amount",
          lookup: Data.CurrencyLookup,
          customFilterAndSearch: (term, rowData) => term == rowData.Currency,
          customSort: (a, b) => a.Amount - b.Amount,
          render: (rowData) => {
            let icon = null;
            if (rowData.Currency === 0) {
              icon = (
                <img
                  src={consts.images.imgEnergy}
                  alt="Energy"
                  style={{ maxHeight: 30, maxWidth: 30 }}
                />
              );
            } else if (rowData.Currency === 1) {
              icon = (
                <img
                  src={consts.images.imgSpirit}
                  alt="Spirit"
                  style={{ maxHeight: 30, maxWidth: 30 }}
                />
              );
            } else if (rowData.Currency === 2) {
              icon = (
                <img
                  src={consts.images.imgDogma}
                  alt="Dogma"
                  style={{ maxHeight: 30, maxWidth: 30 }}
                />
              );
            } else if (rowData.Currency === 3) {
              icon = <AttachMoney />;
            }

            return (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <div style={{ marginRight: 2 }}>{icon}</div>
                <div>{rowData.Amount}</div>
              </div>
            );
          },
        },
        {
          title: "Enabled",
          field: "Enabled",
          type: "boolean",
          filtering: false,
          render: (rowData) =>
            rowData.Enabled === "1" ? <Icon>check</Icon> : <Icon>remove</Icon>,
        },
        {
          title: "Locked",
          field: "Locked",
          type: "boolean",
          filtering: false,
          render: (rowData) =>
            rowData.Locked === "1" ? <Icon>check</Icon> : <Icon>remove</Icon>,
        },
      ],
      data: [],
      dialog: "none",
    };
  }

  componentDidMount() {
    this.refreshData();
  }

  refreshData = () => {
    this.setState({ isLoading: true }, () => {
      server
        .get("economy/pricing")
        .then((response) => {
          this.setState({ data: response.data });
        })
        .catch((error) => {
          console.error(error);
          this.props.showNotification("", "An error occured");
        })
        .finally(() => {
          this.setState({ isLoading: false });
        });
    });
  };

  render() {
    return (
      <Col md={12}>
        <Table
          isLoading={this.state.isLoading}
          tableRef={this.tableRef}
          title="Pricing"
          columns={this.state.columns}
          options={{
            debounceInterval: 500,
          }}
          actions={[
            {
              icon: "refresh",
              tooltip: "Refresh Data",
              isFreeAction: true,
              onClick: this.refreshData,
            },
            {
              icon: "add",
              tooltip: "Add",
              isFreeAction: true,
              onClick: () =>
                this.setState({ dialog: "add", currentPricing: null }),
            },
            (rowData) => ({
              icon: "edit",
              tooltip: "Edit Pricing",
              disabled: rowData.Locked === "1",
              isFreeAction: false,
              onClick: (e, currentPricing) =>
                this.setState({ dialog: "update", currentPricing }),
            }),
          ]}
          data={this.state.data}
        />
        {this.state.dialog != "none" && (
          <EditPricingDialog
            pricingData={this.state.currentPricing}
            mode={this.state.dialog}
            onClose={(result) => {
              this.setState({ dialog: "none", currentPricing: null }, () => {
                if (result) {
                  this.refreshData();
                }
              });
            }}
          />
        )}
      </Col>
    );
  }
}

export default withTheme(withNotifications(EconomyPricing));
