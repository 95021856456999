import React from 'react';
import Table from '../../../../shared/components/table';

export default (props) => (
  <Table
    data={props.inventoryItems}
    columns={[
      {title: 'Inventory Item ID', field: 'InventoryItemID'},
      {title: 'Item ID', field: 'itemID'},
      {title: 'Collection Name', field: 'collectionName'},
      {title: 'Amount', field: 'amount'}
    ]}
    options={{
      maxBodyHeight: null,
      toolbar: false,
      paging: false
    }}
  />
)