import React, { Component } from "react";
import PropTypes from "prop-types";
import SidebarLink from "./SidebarLink";
import SidebarCategory from "./SidebarCategory";

class SidebarContent extends Component {
  static propTypes = {
    // changeToDark: PropTypes.func.isRequired,
    // changeToLight: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      expandedCategory: undefined,
    };
  }

  hideSidebar = () => {
    const { onClick } = this.props;
    onClick();
  };

  onCategoryToggle = (id, callback) => {
    if (this.state.expandedCategory === id) {
      this.setState({ expandedCategory: undefined }, () => callback(false));
    } else {
      this.setState({ expandedCategory: id }, () => callback(true));
    }
  };

  render() {
    // const { changeToDark, changeToLight } = this.props;
    return (
      <div className="sidebar__content">
        <ul className="sidebar__block">
          <SidebarLink
            icon="home"
            title="Dashboard"
            route="/pages/dashboard"
            onClick={this.hideSidebar}
          />
          <SidebarCategory
            id="players"
            title="Players"
            icon="users"
            route="/pages/players"
            collapse={this.state.expandedCategory === "players"}
            onToggle={this.onCategoryToggle}
          >
            <SidebarLink
              title="All Gods"
              route="/pages/players"
              onClick={this.hideSidebar}
            />
            <SidebarLink
              title="New Gods"
              route="/pages/new-gods"
              onClick={this.hideSidebar}
            />
            <SidebarLink
              title="Pantheons"
              route="/pages/pantheons"
              onClick={this.hideSidebar}
            />
            <SidebarLink
              title="Sessions"
              route="/pages/sessions"
              onClick={this.hideSidebar}
            />
            <SidebarLink
              title="Strikes"
              route="/pages/strikes"
              onClick={this.hideSidebar}
            />
          </SidebarCategory>
          <SidebarLink
            title="Actions"
            icon="rocket"
            route="/pages/actions"
            onClick={this.hideSidebar}
          />
          <SidebarLink
            title="Rewards"
            icon="gift"
            route="/pages/rewards"
            onClick={this.hideSidebar}
          />
          <SidebarCategory
            id="economy"
            title="Economy"
            icon="diamond"
            route="/pages/economy"
            collapse={this.state.expandedCategory === "economy"}
            onToggle={this.onCategoryToggle}
          >
            <SidebarLink
              title="Overview"
              route="/pages/economy"
              onClick={this.hideSidebar}
            />
            <SidebarLink
              title="Elements"
              route="/pages/economy-elements"
              onClick={this.hideSidebar}
            />
            <SidebarLink
              title="Rates"
              route="/pages/economy-rates"
              onClick={this.hideSidebar}
            />
            <SidebarLink
              title="Pricing"
              route="/pages/economy-pricing"
              onClick={this.hideSidebar}
            />
          </SidebarCategory>
          <SidebarLink
            title="Galactic Events"
            icon="heart-pulse"
            route="/pages/galactic-events"
            onClick={this.hideSidebar}
          />
          <SidebarLink
            title={
              <span>
                Game Server <i className="lnr lnr-link" />
              </span>
            }
            icon="cloud"
            href="https://ca.ovh.com/auth/"
            onClick={this.hideSidebar}
            target="_blank"
          />
          <SidebarLink
            title={
              <span>
                Analytics <i className="lnr lnr-link" />
              </span>
            }
            icon="chart-bars"
            href="https://developer.cloud.unity3d.com/orgs/apemannn/projects/galaxy-gods/"
            onClick={this.hideSidebar}
            target="_blank"
          />
          <SidebarLink
            title={
              <span>
                Push Notifications <i className="lnr lnr-link" />
              </span>
            }
            icon="smartphone"
            href="https://app.onesignal.com/login"
            onClick={this.hideSidebar}
            target="_blank"
          />
          <SidebarLink
            title={
              <span>
                Black Hole <i className="lnr lnr-link" />
              </span>
            }
            icon="cloud-check"
            href="http://www.ithacanmedia.com/blackhole/login"
            onClick={this.hideSidebar}
            target="_blank"
          />
          <SidebarCategory
            id="support"
            title="Support"
            icon="earth"
            route="/pages/support/my-tickets"
            collapse={this.state.expandedCategory === "support"}
            onToggle={this.onCategoryToggle}
          >
            <SidebarLink
              title="My Tickets"
              route="/pages/support/my-tickets"
              onClick={this.hideSidebar}
            />
            <SidebarLink
              title="New Tickets"
              route="/pages/support/new-tickets"
              onClick={this.hideSidebar}
            />
            <SidebarLink
              title="All Tickets"
              route="/pages/support/all-tickets"
              onClick={this.hideSidebar}
            />
            <SidebarLink
              title={
                <span>
                  Support App <i className="lnr lnr-link" />
                </span>
              }
              icon="cloud-check"
              href="http://138.197.135.11:81/admin"
              onClick={this.hideSidebar}
              target="_blank"
            />
          </SidebarCategory>
          {/* <SidebarLink title={<span>Opensupports <i className="lnr lnr-link"/></span>} href="http://138.197.135.11:81/admin" onClick={this.hideSidebar} target="_blank" /> */}
          <SidebarLink
            title="Settings"
            icon="cog"
            route="/pages/settings"
            onClick={this.hideSidebar}
          />
        </ul>
      </div>
    );
  }
}

export default SidebarContent;
