import React from "react";
import { Typography } from '@material-ui/core';
import moment from "moment";

const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;

export default class PlayerColumn extends React.Component {
  
  getStatusColor = player => {
    let color = "#808080"; // offline - gray
    const lastLogin = this.getFieldValue("LastLogin");
    if (lastLogin) {
      const m = moment(lastLogin);
      var lastLoginMinutes = -1 * m.diff(new Date(), "minutes");
      if (lastLoginMinutes <= 30) {
        color = "#73ff57"; // online - green
      } else if (lastLoginMinutes <= 24 * 60) {
        color = "#ffcc01"; // last24 - yellow
      }
    }

    return color; 
  }

  getFieldValue = (field) => this.props.player[(this.props.fieldPrefix||"") + field];

  render() {
    return (
      <div style={{ display: "flex" }}>
        <img
          style={{
            height: 50,
            width: 50,
            borderRadius: "50%",
            border: "3px solid " + this.getStatusColor(this.props.player),
            backgroundClip: "content-box",
            padding: 1
          }}
          src={this.getFieldValue("PlayerAvatarImageUrl") || Ava}
          alt={this.getFieldValue("PlayerName")}
        />
        <div style={{ flex: 1, paddingLeft: 12 }}>
          <Typography variant="subtitle1">{this.getFieldValue("PlayerName")}</Typography>
          <Typography variant="caption">
            {this.getFieldValue("PlayerTitle1")} of {this.getFieldValue("PlayerTitle2")}
          </Typography>
        </div>
      </div>
    );
  }
}
