import React from 'react';
import {
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  DialogActions,
  DialogTitle,
  Button,
  TextField,
} from '@material-ui/core';
import PlayerColumn from './player-column';
import Data from '../economy/pricing/data';
import withNotifications from '../../notification';
import server from '../../../server';

class AddElementToPlayerDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      formData: {},
      itemData: null
    };
  }

  getItemData = (itemType) => {
    if(itemType == undefined || itemType == null)
      return;
    this.setState({ isLoading: true }, () => {
      server
        .get("economy/pricing/" + itemType)
        .then((response) => {
          
          let result = response.data.map(x=> {
            const item = {};
            // item[x.Id] = x.ItemName;
            item.Id = x.Id;
            item.Name = x.ItemName;
            return item;
          });
          
          result = result.reduce(
            (obj, item) => Object.assign(obj, { [item.Id]: item.Name }), {});

          // console.log();
          // console.log("original", Data.ItemTypeLookup);
          // console.log("result", result);
          
          
          this.setState({ itemData: {...result}});
          
         
        })
        .catch((error) => {
          console.error(error);
          this.props.showNotification("", "An error occured");
        })
        .finally(() => {
          this.setState({ isLoading: false });
        });
    });
  };

  setFieldOfFormData = (field, value, callback) => {
    if(field == 'ItemType'){
      this.getItemData(value);
    }
    // else {
    const formData = { ...this.state.formData };
    formData[field] = value;

    this.setState({ formData }, callback);
    // }
  };

  onSave = () => {
    
    const url = 'players/give-item';
    this.setState({ isLoading: true }, () => {
      server
        .post(url, { 
          itemId: this.state.formData.Item,
          amount: (this.state.formData.Amount == undefined && !Data.ShowAmountField(this.state.formData.ItemType)) ? 1 : this.state.formData.Amount,
          playerId: this.props.player.PlayerDataId,
          itemType: Data.ItemTypeLookup[this.state.formData.ItemType],
          type: Data.GetItemTypeTable(this.state.formData.ItemType)
         })
        .then(response => {
          this.props.showNotification('', 'Data saved successfully', 'success');
          this.props.onClose();
        })
        .catch(error => {
          console.error(error);
          this.props.showNotification('', 'An error occured');
        })
        .finally(() => {
          this.setState({ isLoading: false });
        });
    });
  }

  renderSelect = (label, field, options, onChangeMethod) => (
    <FormControl fullWidth style={{ marginBottom: 15 }}>
      <InputLabel htmlFor={label.replace(' ', '') + '-label-placeholder'}>
        {label}
      </InputLabel>
      <Select
        value={this.state.formData[field]}
        onChange={(e) => this.setFieldOfFormData(field, e.target.value)}
        

        inputProps={{
          name: label.replace(' ', ''),
          id: label.replace(' ', '') + '-label-placeholder',
        }}
        name="item-type"
      >
        {Object.keys(options).map((key) => (
          <MenuItem value={key}>{options[key]}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  renderTextField = (label, field, type = 'text') => (
    <TextField
      label={label}
      value={this.state.formData[field]}
      onChange={e => this.setFieldOfFormData(field, e.target.value)}
      fullWidth
      style={{ marginBottom: 15 }}
      type={type}
    />
  );

  render() {
    return (
      <Dialog open fullWidth>
        <DialogTitle>{`Add element to ${this.props.player.PlayerName}`}</DialogTitle>
        <DialogContent>
          <PlayerColumn player={this.props.player} />
          <div style={{height: 20}}/>
          {this.renderSelect('Item Type', 'ItemType', Data.ItemTypeLookup)}
          {this.state.itemData != null && this.renderSelect('Item', 'Item', this.state.itemData)}
          {Data.ShowAmountField(this.state.formData.ItemType) && this.renderTextField('Amount', 'Amount', 'number')}
        </DialogContent>
        <DialogActions>
          {this.state.isLoading && <CircularProgress />}
          <Button disabled={this.state.isLoading} onClick={this.props.onClose}>
            Close
          </Button>
          <Button
            disabled={this.state.isLoading}
            color="primary"
            onClick={this.onSave}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withNotifications(AddElementToPlayerDialog);
