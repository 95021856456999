import React from "react";
import { Typography } from '@material-ui/core';


export default class Patheons extends React.Component {
  
  render() {
    return (
      <Typography variant="h2" style={{textAlign: 'center'}}>
        Coming Soon
      </Typography>
    );
  }
}
