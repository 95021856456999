import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
import ProfileMain from "./components/ProfileMain";
import ProfileTabs from "./components/ProfileTabs";
import Progress from "../../../shared/components/progress";
import server from "../../../server";
import { Typography } from "@material-ui/core";
import EditPlayerCard from "../../components/edit-player-card";

class PlayerProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      data: null,
      showEdit: false
    };
  }

  componentDidMount() {
    this.fillData();
  }

  fillData = () => {
    const id = this.props.playerDataId || this.props.match.params.id;

    this.setState({ isLoading: true }, () => {
      server
        .get("players/detail/" + id)
        .then(response => {
          this.setState({
            isLoading: false,
            data: response.data
          });
        })
        .catch(error => {
          console.error(error);
          this.setState({ isLoading: false });
        });
    });
  };

  render() {
    if (this.state.isLoading) {
      return <Progress />;
    }

    if (!this.state.data) {
      return <Typography>Data could not be loaded.</Typography>;
    }

    return (
      <Container>
        <div className="profile">
          <Row>
            <Col md={12} lg={12} xl={4}>
              <Row>
                <ProfileMain
                  player={this.state.data.player}
                  onDataUpdated={this.fillData}
                  onEditClicked={() =>
                    this.setState(state => ({ showEdit: !state.showEdit }))
                  }
                />
              </Row>
            </Col>
            {this.state.showEdit ? (
              <Col md={12} lg={12} xl={8} style={{ paddingRight: 30 }}>
                <Row>
                  <EditPlayerCard
                    player={this.state.data.player}
                    onClose={result => {
                      this.setState({ showEdit: false }, () => {
                        if (result) {
                          this.fillData();
                        }
                      });
                    }}
                  />
                </Row>
              </Col>
            ) : (
              <ProfileTabs data={this.state.data} />
            )}
          </Row>
        </div>
      </Container>
    );
  }
}

export default PlayerProfile;
