import * as React from "react";
import { Col } from "reactstrap";
import Table from "../../../shared/components/table";
import { MTableActions } from "material-table";
import server from "../../../server";
import PlayerInfo from "./player-info";
import EditPlayerDialog from "../../components/edit-player-dialog";
import { withRouter } from "react-router-dom";
import withNotifications from "../../notification";
import TimeAgo from "../../../shared/components/time-ago";
import PlayerColumn from "./player-column";
import PlayerActionMenu from "./player-action-menu";


const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;

class Players extends React.Component {
  tableRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      columns: [
        {
          title: "Id",
          field: "PlayerDataId",
          filtering: false,
          editable: "never",
          cellStyle: { paddingLeft: 5, paddingRight: 0, maxWidth: 60, width: 60 },
          headerStyle: { paddingLeft: 5, paddingRight: 0, maxWidth: 60, width: 60 }
        },
        {
          title: "Player",
          field: "LastLogin",          
          defaultSort: this.props.defaultSort === "LastLogin" ? "desc" : undefined,
          render: player => <PlayerColumn player={player}/>
        },
        {
          title: "Created On",
          field: "CreatedOn",
          hidden: !this.props.showCreateOn,
          defaultSort: this.props.defaultSort === "CreatedOn" ? "desc" : undefined,
          filtering: false,
          render: player => <TimeAgo date={player.CreatedOn} />
        },
        {
          title: "Frequency",
          field: "Frequency",
          filtering: false,
          editable: "never",
          type: "numeric",
          render: rowData => Number(rowData.Frequency).toFixed(2)
        }
      ],
      data: [],
      editPlayer: null
    };
  }

  render() {
    return (
      <>
        <Col md={12}>
          <Table
            tableRef={this.tableRef}
            title={this.props.title}
            components={{
              Actions: props => {/*console.log(props);*/ return (
                <div style={{display: 'flex'}}>
                  <MTableActions {...props}/>
                  {props.data && <PlayerActionMenu player={props.data}/>}
                </div>
              )}
            }}
            columns={this.state.columns}
            options={{
              debounceInterval: 500
            }}
            actions={[
              {
                icon: "person",
                tooltip: "Full Profile",
                onClick: (e, player) =>
                  this.props.history.push(
                    "/pages/player-profile/" + player.PlayerDataId
                  )
              },
              {
                icon: "edit",
                tooltip: "Edit Player",
                onClick: (e, editPlayer) => this.setState({ editPlayer })
              }
            ]}
            data={query =>
              new Promise((resolve, reject) => {
                const tmp = { ...query };
                if (!tmp.orderBy) {
                  tmp.orderBy = { ...this.state.columns.find(c => c.field === this.props.defaultSort) };
                  tmp.orderDirection = "desc";
                }

                server
                  .post("players", tmp)
                  .then(response => {
                    resolve({
                      data: response.data.data,
                      page: query.page,
                      totalCount: response.data.totalCount
                    });
                  })
                  .catch(error => {
                    console.error(error);
                    this.props.showNotification("", "An error occured");

                    resolve({
                      data: [],
                      page: 0,
                      totalCount: 0
                    });
                  });
              })
            }
            detailPanel={[
              {
                tooltip: "Player Details",
                render: player => (
                  <div
                    style={{
                      backgroundColor: "#29292f"
                    }}
                  >
                    <PlayerInfo playerDataId={player.PlayerDataId} />
                  </div>
                )
              }
            ]}
            onRowClick={(event, rowData, togglePanel) => togglePanel()}
          />
          {this.state.editPlayer && (
            <EditPlayerDialog
              player={this.state.editPlayer}
              onClose={result => {
                this.setState({ editPlayer: null }, () => {
                  if (result && this.tableRef.current) {
                    this.tableRef.current.onQueryChange();
                  }
                });
              }}
            />
          )}
        </Col>
      </>
    );
  }
}

export default withRouter(withNotifications(Players));
