import React from "react";
import { Col, Container, Row } from "reactstrap";
import PropTypes from "prop-types";
import CurrentUsers from "./components/CurrentUsers";
import ActiveUsers from "./components/ActiveUsers";
import ValueBox from "./components/ValueBox";
import AppTileClicks from "./components/AppTileClicks";
import WeeklyStatMobile from "./components/WeeklyStatMobile";
import SocialMarketing from "./components/SocialMarketing";
import server from "../../../server";
import withNotifications from "../../notification";
import {
  Icon,
  IconButton,
  Tooltip,
  Switch,
  CircularProgress,
  Select,
  MenuItem,
} from "@material-ui/core";
import Currencies from "./components/Currencies";
import GalaxyPopulation from "./components/GalaxyPopulation";

const emptyData = {
  count: 0,
  prevCount: 0,
};

class MobileAppDashboard extends React.Component {
  timer = null;

  constructor(props) {
    super(props);
    this.state = {
      autoRefresh: false,
      isLoading: false,
      range: 1440,
      dashboardData: {
        activeGodsData: emptyData,
        newGodsData: emptyData,
        planetData: emptyData,
        sessionData: emptyData,
      },
    };
  }

  componentDidMount() {
    this.refreshDashboardData();
  }

  refreshDashboardData = () => {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        server
          .get("dashboard/boxes/" + this.state.range)
          .then((result) => this.setState({ dashboardData: result.data }))
          .catch(() => this.props.showNotification("", "An error occured"))
          .finally(() => this.setState({ isLoading: false }));
      }
    );
  };

  autoRefreshChanged = (value) => {
    this.setState({ autoRefresh: value });

    if (value) {
      this.timer = setInterval(this.refreshDashboardData, 1000 * 60); // 1 minute
    } else {
      clearInterval(this.timer);
    }
  };

  render() {
    return (
      <Container className="dashboard">
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1 }}>
            <h3 className="page-title">{"Dashboard"}</h3>
          </div>
          <div>
            <Select
              value={this.state.range}
              onChange={(e) =>
                this.setState(
                  { range: e.target.value },
                  this.refreshDashboardData
                )
              }
            >
              <MenuItem value={15}>15 minutes</MenuItem>
              <MenuItem value={60}>1 hour</MenuItem>
              <MenuItem value={720}>12 hours</MenuItem>
              <MenuItem value={1440}>24 hours</MenuItem>
              <MenuItem value={10080}>1 week</MenuItem>
              <MenuItem value={43200}>1 month</MenuItem>
              <MenuItem value={525600}>1 year</MenuItem>
            </Select>
            <Tooltip title="Auto Refresh">
              <Switch
                checked={this.state.autoRefresh}
                onChange={(e) => this.autoRefreshChanged(e.target.checked)}
                color="primary"
              />
            </Tooltip>
            {this.state.isLoading ? (
              <CircularProgress size={30} style={{ margin: "0 9px" }} />
            ) : (
              <Tooltip title="Refresh">
                <IconButton onClick={this.refreshDashboardData}>
                  <Icon>refresh</Icon>
                </IconButton>
              </Tooltip>
            )}
          </div>
        </div>
        <Row>
          <ValueBox
            data={this.state.dashboardData.activeGodsData}
            title="Active Gods"
            color="lime"
            showChange={false}
          />
          <ValueBox
            data={this.state.dashboardData.sessionData}
            title="Sessions"
            color="pink"
            showChange
          />
          <ValueBox
            data={this.state.dashboardData.newGodsData}
            title="New Gods"
            color="blue"
            showChange
          />
          <ValueBox
            data={this.state.dashboardData.planetData}
            title="New Planets"
            color="turquoise"
            showChange
          />
        </Row>
        <Row>
          <Currencies />
          <GalaxyPopulation />
          <ActiveUsers />
        </Row>
        <Row>
          <AppTileClicks />
          <WeeklyStatMobile />
          <SocialMarketing />
        </Row>
      </Container>
    );
  }
}

export default withNotifications(MobileAppDashboard);
