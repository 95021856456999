import React, { PureComponent } from "react";
import {
  Card,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap";
import classnames from "classnames";
import PlanetsTab from "./planets-tab";
import TemplesTab from "./temples-tab";
import InventoryTab from "./inventory-tab";

export default class ProfileTabs extends PureComponent {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "1"
    };
  }

  toggle(tab) {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  render() {
    const { activeTab } = this.state;
    return (
      <Col md={12} lg={12} xl={8}>
        <Card>
          <div className="profile__card tabs tabs--bordered-bottom">
            <div className="tabs__wrap">
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      this.toggle("1");
                    }}
                  >
                    Planets
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      this.toggle("2");
                    }}
                  >
                    Temples
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "3" })}
                    onClick={() => {
                      this.toggle("3");
                    }}
                  >
                    Inventory
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab} style={{padding: 0}}>
                <TabPane style={{padding: 0}} tabId="1"><PlanetsTab planets={this.props.data.planets}/></TabPane>
                <TabPane style={{padding: 0}} tabId="2"><TemplesTab temples={this.props.data.temples}/></TabPane>
                <TabPane style={{padding: 0}} tabId="3"><InventoryTab inventoryItems={this.props.data.inventoryItems}/></TabPane>
              </TabContent>
            </div>
          </div>
        </Card>
      </Col>
    );
  }
}
