import { Tooltip, Typography } from "@material-ui/core";
import React, { Component } from "react";
import { Button, ButtonGroup, ButtonToolbar, Card, CardBody, Col } from "reactstrap";
import TimeAgo from '../../../../shared/components/time-ago';
import EditPlayerDialog from "../../../components/edit-player-dialog";

const Ava = `${process.env.PUBLIC_URL}/img/12.png`;
const imgEnergy = `${process.env.PUBLIC_URL}/img/IcoEnergy.png`;
const imgSpirit = `${process.env.PUBLIC_URL}/img/IcoSpirit.png`;
const imgDogma = `${process.env.PUBLIC_URL}/img/IcoDogma.png`;

class ProfileMain extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isEditDialogOpen: false
    };
  }
  render() {
    return (
      <Col md={12} lg={12} xl={12}>
        <Card>
          <CardBody className="profile__card">
            <div className="profile__information">
              <div className="profile__avatar">
                <img
                  src={this.props.player.PlayerAvatarImageUrl || Ava}
                  alt="avatar"
                />
              </div>
              <div className="profile__data">
                <p className="profile__name">{this.props.player.PlayerName}</p>
                <p className="profile__work">
                  {this.props.player.PlayerTitle1} of{" "}
                  {this.props.player.PlayerTitle2}
                </p>
                <p className="profile__work">
                  Email: {this.props.player.GenericUserCredentialEmail}
                </p>

                <Typography>Last Login: <TimeAgo date={this.props.player.LastLogin}/></Typography>
                <br/>

                <Typography>
                  Frequency: {Number(this.props.player.Frequency).toFixed(2)}
                </Typography>
                <Typography>Cluster Id: {3}</Typography>
                <Typography>Solar Chakra: {3}</Typography>
                <ButtonToolbar>
                  <ButtonGroup className="btn-group--icons">
                    <Tooltip title="Send Message">
                      <Button color="primary">
                        <span className="lnr lnr-envelope" />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Push Notification">
                      <Button color="primary">
                        <span className="lnr lnr-smartphone" />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Give Item">
                      <Button color="primary">
                        <span className="lnr lnr-diamond" />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Edit">
                      <Button
                        color="primary"
                        onClick={() => {
                          this.props.onEditClicked();
                          //this.setState({ isEditDialogOpen: true });
                        }}
                      >
                        <span className="lnr lnr-pencil" />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Delete Account">
                      <Button color="primary">
                        <span className="lnr lnr-cross" />
                      </Button>
                    </Tooltip>
                  </ButtonGroup>
                </ButtonToolbar>
              </div>
            </div>
            <div className="profile__stats">
              <div className="profile__stat">
                <p className="profile__stat-number">
                  <img
                    src={imgEnergy}
                    alt="Energy"
                    style={{ width: 50, marginRight: 10 }}
                  />
                  {Math.trunc(this.props.player.Currency0)}
                </p>
              </div>
              <div className="profile__stat">
                <p className="profile__stat-number">
                  <img
                    src={imgSpirit}
                    alt="Spirit"
                    style={{ width: 50, marginRight: 10 }}
                  />
                  {Math.trunc(this.props.player.Currency1)}
                </p>
              </div>
              <div className="profile__stat">
                <p className="profile__stat-number">
                  <img
                    src={imgDogma}
                    alt="Dogma"
                    style={{ width: 50, marginRight: 10 }}
                  />
                  {Math.trunc(this.props.player.Currency2)}
                </p>
              </div>
            </div>
          </CardBody>
        </Card>
        {this.state.isEditDialogOpen && (
          <EditPlayerDialog
            player={this.props.player}
            onClose={result => {
              this.setState({ isEditDialogOpen: false }, () => {
                result && this.props.onDataUpdated();
              });
            }}
          />
        )}
      </Col>
    );
  }
}

export default ProfileMain;
