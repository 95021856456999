import React from 'react';
import moment from 'moment';
import { Tooltip } from '@material-ui/core';

export default ({date}) => {
  if(!date) {
    return "-"
  }

  var m = moment(date);
  return (
    <Tooltip title={m.format('LLL')}>    
      <span>{m.fromNow()}</span>
    </Tooltip>
  )
}
