import { CircularProgress, Typography, Grid } from "@material-ui/core";
import * as React from "react";
import { Card, CardBody, Col } from "reactstrap";
import server from "../../../server";
import TimeAgo from "../../../shared/components/time-ago";

const Ava = `${process.env.PUBLIC_URL}/img/12.png`;
const imgEnergy = `${process.env.PUBLIC_URL}/img/IcoEnergy.png`;
const imgSpirit = `${process.env.PUBLIC_URL}/img/IcoSpirit.png`;
const imgDogma = `${process.env.PUBLIC_URL}/img/IcoDogma.png`;

export default class PlayerInfo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      detailData: null
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true }, () => {
      server
        .get("players/detail/" + this.props.playerDataId)
        .then(response => {
          this.setState({
            isLoading: false,
            detailData: response.data
          });
        })
        .catch(error => {
          console.error(error);
          this.setState({ isLoading: false });
        });
    });
  }

  renderDetailData = () => {
    if (!this.state.detailData) {
      return null;
    }

    return (
      <Col md={12} lg={12} xl={12} style={{padding: 0}}>
        <Card style={{paddingBottom: 0}}>
          <CardBody className="profile__card">
            <div className="profile__information">
              <div className="profile__avatar">
                <img
                  src={this.state.detailData.player.PlayerAvatarImageUrl || Ava}
                  alt="avatar"
                />
              </div>
              <div className="profile__data">
                <Grid container>
                  <Grid item xs={12} sm={4} md={4} lg={4}>
                    <p className="profile__name">
                      {this.state.detailData.player.PlayerName}
                    </p>
                    <p className="profile__work">
                      Email:{" "}
                      {this.state.detailData.player.GenericUserCredentialEmail}
                    </p>

                    <Typography>
                      Last Login:{" "}
                      <TimeAgo date={this.state.detailData.player.LastLogin} />
                    </Typography>
                    <Typography>
                      Created On:{" "}
                      <TimeAgo date={this.state.detailData.player.CreatedOn} />
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={4} md={4} lg={4}>
                    <Typography>
                      Frequency:{" "}
                      {Number(this.state.detailData.player.Frequency).toFixed(
                        2
                      )}
                    </Typography>
                    <Typography>Cluster Id: {3}</Typography>
                    <Typography>Solar Chakra: {3}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4}>
                    <Typography>
                      Total Planets: {this.state.detailData.planets.length}
                    </Typography>
                    <Typography>
                      Total Temples: {this.state.detailData.temples.length}
                    </Typography>
                    <Typography>
                      Total Inventories:{" "}
                      {this.state.detailData.inventoryItems.length}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            </div>
            <div className="profile__stats">
              <div className="profile__stat">
                <p className="profile__stat-number">
                  <img
                    src={imgEnergy}
                    alt="Energy"
                    style={{ width: 40, marginRight: 10 }}
                  />
                  {Math.trunc(this.state.detailData.player.Currency0)}
                </p>
              </div>
              <div className="profile__stat">
                <p className="profile__stat-number">
                  <img
                    src={imgSpirit}
                    alt="Spirit"
                    style={{ width: 40, marginRight: 10 }}
                  />
                  {Math.trunc(this.state.detailData.player.Currency1)}
                </p>
              </div>
              <div className="profile__stat">
                <p className="profile__stat-number">
                  <img
                    src={imgDogma}
                    alt="Dogma"
                    style={{ width: 40, marginRight: 10 }}
                  />
                  {Math.trunc(this.state.detailData.player.Currency2)}
                </p>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    );
  };

  render() {
    return (
      this.state.isLoading ? (
        <div style={{ padding: 10 }}><CircularProgress /></div>
      ) : (
        <Typography>{this.renderDetailData()}</Typography>
      )
    );
  }
}
