import React, { PureComponent } from "react";
import { Field, reduxForm } from "redux-form";
import EyeIcon from "mdi-react/EyeIcon";
import KeyVariantIcon from "mdi-react/KeyVariantIcon";
import AccountOutlineIcon from "mdi-react/AccountOutlineIcon";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import renderCheckBoxField from "../../../shared/components/form/CheckBox";
import server from "../../../server";
import Progress from "../../../shared/components/progress";
import withNotifications from "../../notification";
import { connect } from "react-redux";
import { login } from "../../../redux/actions/app-context-actions";

class LogInForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired
  };

  constructor() {
    super();
    this.state = {
      isLoading: false,
      showPassword: false,
      username: "",
      password: ""
    };
  }

  handleSubmit = event => {
    event.preventDefault();
    if (!this.state.username || !this.state.password) {
      this.props.showNotification("", "Please enter username and password");
      return;
    }

    this.setState({ isLoading: true }, () => {
      server
        .post("/auth/login", {
          username: this.state.username,
          password: this.state.password
        })
        .then(response => {
          if (response.data.status === "success") {
            this.props.login(response.data.data.sessionData, response.data.data.settings);
            server.defaults.headers.common["Authorization"] = JSON.stringify(
              response.data.data.sessionData
            );
            this.props.history.push("/pages/dashboard");
          } else {
            if (response.data.message == "INVALID_CREDENTIALS") {
              this.props.showNotification(
                "Warning",
                "Wrong username or password",
                "warning"
              );
            } else {
              this.props.showNotification(
                "Error",
                "System Error: " + response.data.message,
                "danger"
              );
            }
          }
        })
        .catch(error => {
          this.props.showNotification(
            "Error",
            "Internal server error occurred",
            "danger"
          );
        })
        .finally(() => {
          this.setState({ isLoading: false });
        });
    });
  };

  showPassword = e => {
    e.preventDefault();
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));
  };

  render() {
    const { showPassword } = this.state;

    return (
      <form className="form" onSubmit={this.handleSubmit}>
        <div className="form__form-group">
          <span className="form__form-group-label">Username</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <AccountOutlineIcon />
            </div>
            <Field
              name="name"
              component="input"
              type="text"
              placeholder="Name"
              value={this.state.username}
              disabled={this.state.isLoading}
              onChange={event =>
                this.setState({ username: event.target.value })
              }
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Password</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <KeyVariantIcon />
            </div>
            <Field
              name="password"
              component="input"
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              value={this.state.password}
              disabled={this.state.isLoading}
              onChange={event =>
                this.setState({ password: event.target.value })
              }
            />
            <button
              className={`form__form-group-button${
                showPassword ? " active" : ""
              }`}
              onClick={e => this.showPassword(e)}
              type="button"
            >
              <EyeIcon />
            </button>
          </div>
        </div>

        {this.state.isLoading ? (
          <Progress />
        ) : (
          <button
            className="btn btn-primary account__btn account__btn--small"
            disabled={this.state.isLoading}
            type="submit"
          >
            Sign In
          </button>
        )}
      </form>
    );
  }
}

const mapStateToProps = state => ({
  appContext: state.appContext
});

const mapDispatchToProps = dispatch => ({
  login: (sessionData, settings) => dispatch(login(sessionData, settings))
});

export default reduxForm({
  form: "log_in_form"
})(
  withRouter(
    withNotifications(
      connect(
        mapStateToProps,
        mapDispatchToProps
      )(LogInForm)
    )
  )
);
