import * as React from "react";
import {
  Tooltip,
  IconButton,
  TextField,
  Typography,
  Switch,
} from "@material-ui/core";
import Check from "@material-ui/icons/Check";
import Clear from "@material-ui/icons/Clear";

export default class SingleEditField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isEditing: false,
      value: props.value || "",
    };
  }

  renderTextField = () => {
    if (this.state.isEditing) {
      return (
        <TextField
          autoFocus
          disabled={this.props.isButtonsDisabled}
          value={this.state.value}
          onChange={(e) => this.setState({ value: e.target.value })}
          inputProps={{
            style: { textAlign: "right", width: this.props.textFieldWidth || 80, fontSize: "0.875rem" },
          }}
          InputProps={{
            startAdornment: (
              <>
                <Tooltip title="Save">
                  <IconButton
                    size="small"
                    disableRipple
                    disabled={this.props.isButtonsDisabled}
                    style={{ padding: 4 }}
                    onClick={(e) => {
                      this.props.onChange &&
                        this.props.onChange(this.state.value, () =>
                          this.setState({ isEditing: false })
                        );
                    }}
                  >
                    <Check />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Cancel">
                  <IconButton
                    size="small"
                    disableRipple
                    disabled={this.props.isButtonsDisabled}
                    style={{ padding: 4 }}
                    onClick={() =>
                      this.setState({
                        isEditing: false,
                        value: this.props.value,
                      })
                    }
                  >
                    <Clear />
                  </IconButton>
                </Tooltip>
              </>
            ),
          }}
        />
      );
    } else {
      return (
        <Typography
          style={{ borderBottom: "1px dotted white", cursor: "pointer" }}
          variant="body2"
          onClick={() => this.setState({ isEditing: true })}
        >
          {this.state.value}
        </Typography>
      );
    }
  };

  renderSwitch = () => {
    return (
      <Switch
        disabled={this.props.isButtonsDisabled}
        checked={this.props.value}
        onChange={(e) => {
          this.props.onChange && this.props.onChange(e.target.checked);
        }}
      />
    );
  };

  renderValue = () => {
    if (this.props.editable) {
      if (this.props.controlType === "switch") {
        return this.renderSwitch();
      } else {
        return this.renderTextField();
      }
    } else {
      return <Typography variant="body2">{this.props.value}</Typography>;
    }
  };

  render() {
    return (
      <div
        style={{
          display: "flex",
          color: "white",
          height: 48,
          alignItems: "center",
        }}
      >
        <Typography style={{ flex: 1 }} variant="body2">
          {this.props.label}
        </Typography>
        {this.renderValue()}
      </div>
    );
  }
}
