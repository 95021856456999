/* eslint-disable react/no-array-index-key */
import React from "react";
import Panel from "../../../../shared/components/Panel";
import server from "../../../../server";
import withNotifications from "../../../notification";
import * as consts from "../../../../consts";
import numeral from "numeral";
import { Tooltip } from "@material-ui/core";

class GalaxyPopulation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      data: {},
    };
  }

  componentDidMount() {
    this.refreshData();
  }

  refreshData = () => {
    this.setState({ isLoading: true }, () => {
      server
        .get("economy/galaxy-population")
        .then((response) => this.setState({ data: response.data }))
        .catch((error) => {
          console.error(error);
          this.props.showNotification("", "An error occured");
        })
        .finally(() => {
          this.setState({ isLoading: false });
        });
    });
  };

  renderValue = (title, value = 0) => {
    return (
      <div style={{ display: "flex" }}>
        <div style={{ flex: 1 }}>{title}</div>
        <div style={{ textAlign: "right" }}>{numeral(value).format("0,0")}</div>
      </div>
    );
  };

  render() {
    return (
      <Panel
        lg={3}
        xl={3}
        md={12}
        title="Galaxy Population"
        subhead="Totals"
        isLoading={this.state.isLoading}
        onRefresh={this.refreshData}
      >
        <div className="dashboard__current-users">
          <div style={{ width: "100%", color: "#AAA", marginTop: 15 }}>
            {this.renderValue("Players", this.state.data.totalPlayers)}
            {this.renderValue("Active Players", this.state.data.totalActivePlayers)}
            {this.renderValue("Planets", this.state.data.totalPlanets)}
            {this.renderValue("Temples", this.state.data.totalTemples)}
            {this.renderValue("Inhabitants", this.state.data.totalLevel)}
            {this.renderValue("Moons", this.state.data.totalMoons)}
            {this.renderValue("Strikes", this.state.data.totalStrikes)}
          </div>
        </div>
      </Panel>
    );
  }
}

export default withNotifications(GalaxyPopulation);
