import reduceFileSize from './image-reduce';
import server from "../../server";



export default async (file, maxSize) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    maxSize = maxSize || (600 * 600);

    reduceFileSize(file, 600 * 600, 1000, Infinity, 0.9, reducedFile => {
      if (!reducedFile) {
        reducedFile = file
      }

      reader.readAsDataURL(reducedFile);

      reader.onload = () => {
        const block = reader.result.split(';');
        const contentType = block[0].split(':')[1];
        const data = new FormData();

        urltoFile(reader.result, file.name, contentType)
          .then((uploadFile) => {
            data.append('file', uploadFile);
            server.post("disk/upload", data, {
              headers: {
                'content-type': 'multipart/form-data'
              }
            }).then((response) => {
              resolve(response.data);
            }).catch(err => {
              console.log(err);
              reject(err);
            });
          });
      };

      reader.onerror = (error) => {
        reject(error)
      };
    });
  });
}

export function urltoFile(url, filename, mimeType) {
  return fetch(url)
    .then(res => res.arrayBuffer())
    .then(buf => new File([buf], filename, { type: mimeType }));
}

export function toImageUrl(url) {
  return server.defaults.baseURL + "/disk/" + url;
}

