import React from 'react';
import Table from '../../../../shared/components/table';

export default (props) => (
  <Table
    data={props.temples}
    columns={[
      {title: 'Temple ID', field: 'templeID'},
      {title: 'Item ID', field: 'itemID'},
      {title: 'Planet ID', field: 'planetID'},
      {title: 'Inhabitants', field: 'Inhabitants'}
    ]}
    options={{
      maxBodyHeight: null,
      toolbar: false,
      paging: false
    }}
  />
)