import React from "react";
import { Icon, IconButton, Menu, MenuItem, Tooltip } from "@material-ui/core";
import AddElementToPlayerDialog from "./add-element-to-player-dialog";

export default class PlayerActionMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      showAddElementToPlayerDialog: false,
    };
  }

  render() {
    const { anchorEl, showAddElementToPlayerDialog } = this.state;

    return (
      <>
        <Tooltip title="Actions">
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              this.setState({ anchorEl: e.currentTarget });
            }}
          >
            <Icon>more_vert</Icon>
          </IconButton>
        </Tooltip>

        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => this.setState({ anchorEl: null })}
        >          
          <MenuItem
            onClick={(e) => {
              this.setState({
                anchorEl: null,
                showAddElementToPlayerDialog: true,
              });
              e.stopPropagation();
            }}
          >
            Give To Player
          </MenuItem>
        </Menu>

        {showAddElementToPlayerDialog && (
          <AddElementToPlayerDialog 
            player={this.props.player}
            onClose={() => this.setState({showAddElementToPlayerDialog: false})}
          />
        )}
      </>
    );
  }
}
