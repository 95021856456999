import { Typography } from "@material-ui/core";
import * as React from "react";
import { Col } from "reactstrap";
import server from "../../../server";
import withNotifications from "../../notification";
import Table from "../../../shared/components/table";

class EconomyElements extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      columns: [
        { title: "Id", field: "itemID" },
        { title: "Name", field: "name" },
        { title: "Count", field: "count" }
      ],
      data: []
    };
  }

  componentDidMount() {
    this.refreshData();
  }

  refreshData = () => {
    this.setState({ isLoading: true }, () => {
      server
        .get("economy/elements")
        .then(response => {
          this.setState({ data: response.data });
        })
        .catch(error => {
          console.error(error);
          this.props.showNotification("", "An error occured");
        })
        .finally(() => {
          this.setState({ isLoading: false });
        });
    });
  };

  render() {
    return (
      <Col md={12}>
        <Table
          isLoading={this.state.isLoading}
          tableRef={this.tableRef}
          title="Elements"
          columns={this.state.columns}
          options={{
            debounceInterval: 500
          }}
          actions={[
            {
              icon: "refresh",
              tooltip: 'Refresh Data',
              isFreeAction: true,
              onClick: this.refreshData
            }
          ]}
          data={this.state.data}
        />
      </Col>
    );
  }
}

export default withNotifications(EconomyElements);
