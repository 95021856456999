/* eslint-disable react/no-array-index-key */
import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from "recharts";
import Panel from "../../../../shared/components/Panel";
import server from "../../../../server";
import withNotifications from "../../../notification";
import { Select, MenuItem } from "@material-ui/core";

class ActiveUsers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      range: "month",
      data: []
    };
  }

  componentDidMount() {
    this.refreshDashboardData();
  }

  refreshDashboardData = () => {
    this.setState(
      {
        isLoading: true
      },
      () => {
        server
          .get("dashboard/active-users/" + this.state.range)
          .then(result => this.setState({ data: result.data }))
          .catch(() => this.props.showNotification("", "An error occured"))
          .finally(() => this.setState({ isLoading: false }));
      }
    );
  };

  tickFormer = tick => `${tick / 1000}k`;

  render() {
    return (
      <Panel
        lg={6}
        xl={6}
        md={12}
        title="Active Users"
        subhead="See how users involve into app"
        isLoading={this.state.isLoading}
        onRefresh={this.refreshDashboardData}
        topComponent={() => (
          <Select
            value={this.state.range}
            onChange={e => this.setState({ range: e.target.value }, this.refreshDashboardData)}
            style={{fontSize: 12}}
          >
            <MenuItem value="week">Week</MenuItem>
            <MenuItem value="month">Month</MenuItem>
            <MenuItem value="year">Year</MenuItem>
          </Select>
        )}
      >
        <ResponsiveContainer
          height={190}
          className="dashboard__active-users-chart"
        >
          <LineChart height={195} data={this.state.data}>
            <YAxis
              tickLine={false}
              tickFormatter={this.tickFormer}
              interval="preserveStartEnd"
              width={50}
              tick={{ transform: "translate(-30, 0)", fontSize: 11 }}
            />
            <XAxis padding={{ left: 30, right: 30 }} dataKey="name" />
            <CartesianGrid vertical={false} />
            <Tooltip dataKey="name" />
            <Line
              type="linear"
              dataKey="count"
              dot={false}
              stroke="#b8e986"
              strokeWidth={2}
            />
          </LineChart>
        </ResponsiveContainer>
      </Panel>
    );
  }
}

export default withNotifications(ActiveUsers);
