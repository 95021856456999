import * as React from "react";
import { Col } from "reactstrap";
import Table from "../../../shared/components/table";
import server from "../../../server";
import { withRouter } from "react-router-dom";
import withNotifications from "../../notification";
import PlayerColumn from "./player-column";
import TimeAgo from "../../../shared/components/time-ago";

const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;

class Strikes extends React.Component {
  tableRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      columns: [
        {
          title: 'Strike Id',
          field: 'StrikeId',
        },
        {
          title: "Attacked On",
          field: "AttackedOn",
          render: player => <TimeAgo date={player.AttackedOn} />
        },
        {
          title: "Attacker",
          render: player => <PlayerColumn player={player} fieldPrefix="Attacker"/>
        },
        {
          title: "Victim",
          render: player => <PlayerColumn player={player} fieldPrefix="Victim"/>
        },
        {
          title: 'Planet Name',
          field: 'PlanetName'
        },
        {
          title: 'Restored',
          field: 'Restored',
          type: 'boolean'
        }
      ],
      data: []
    };
  }

  render() {
    return (
      <>
        <Col md={12}>
          <Table
            tableRef={this.tableRef}
            title="Strikes"
            columns={this.state.columns}
            options={{
              debounceInterval: 500,
              filtering: false,
              sort: false
            }}            
            data={query =>
              new Promise((resolve, reject) => {
                const tmp = { ...query };
                // if (!tmp.orderBy) {
                //   tmp.orderBy = {
                //     ...this.state.columns.find(c => c.field === "StrikeStep")
                //   };
                //   tmp.orderDirection = "desc";
                // }

                server
                  .post("players/strikes", tmp)
                  .then(response => {
                    resolve({
                      data: response.data.data,
                      page: query.page,
                      totalCount: response.data.totalCount
                    });
                  })
                  .catch(error => {
                    console.error(error);
                    this.props.showNotification("", "An error occured");

                    resolve({
                      data: [],
                      page: 0,
                      totalCount: 0
                    });
                  });
              })
            }
          />
        </Col>
      </>
    );
  }
}

export default withRouter(withNotifications(Strikes));
