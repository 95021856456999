import React from "react";
import { Col } from "reactstrap";
import Table from "../../../shared/components/table";
import server from "../../../server";
import TimeAgo from "../../../shared/components/time-ago";
import moment from "moment";
import TicketPopover from "./components/ticket-popover";
import {
  Chip,
  Toolbar,
  Typography,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem
} from "@material-ui/core";
import withNotifications from "../../notification";
import { connect } from "react-redux";

class MyTickets extends React.Component {
  tableRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      numberAnchorEl: null,
      showClosedTickets: false,
      selectedDepartment: "",
      columns: [
        {
          title: "Number",
          field: "ticketNumber",
          render: ticket => <TicketPopover ticket={ticket} />
        },
        { title: "Title", field: "title" },
        {
          title: "Priority",
          field: "priority",
          render: ticket => (
            <Chip
              label={ticket.priority}
              color="primary"
              style={{ width: 60, textTransform: "capitalize" }}
            />
          )
        },
        { title: "Department", field: "department.name" },
        { title: "Author", field: "author.name" },
        {
          title: "Date",
          field: "date",
          render: ticket => (
            <TimeAgo date={moment(ticket.date, "YYYYMMDDHHmm")} />
          )
        }
      ],
      data: []
    };
  }

  render() {
    return (
      <>
        <Col md={12}>
          <Table
            tableRef={this.tableRef}
            title="My Tickets"
            columns={this.state.columns}
            components={{
              Toolbar: props => (
                <Toolbar>
                  {props.title && (
                    <div style={{ flex: "0 0 auto" }}>
                      <Typography variant="h6">{props.title}</Typography>
                    </div>
                  )}
                  <div style={{ flex: "1 1 10%" }} />
                  <FormControl style={{ marginRight: 20 }}>
                    <Select
                      value={this.state.selectedDepartment}
                      onChange={e =>
                        this.setState(
                          { selectedDepartment: e.target.value },
                          () => {
                            this.tableRef.current &&
                              this.tableRef.current.onQueryChange();
                          }
                        )
                      }
                      displayEmpty
                      inputProps={{
                        name: "department",
                        id: "department-select"
                      }}
                    >
                      <MenuItem value="">All Departmants</MenuItem>
                      {this.props.appContext.settings.departments.map(d => (
                        <MenuItem value={d.id}>{d.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControlLabel
                    style={{ marginTop: 10 }}
                    control={
                      <Checkbox
                        checked={this.state.showClosedTickets}
                        onChange={e =>
                          this.setState(
                            { showClosedTickets: e.target.checked },
                            () => {
                              this.tableRef.current &&
                                this.tableRef.current.onQueryChange();
                            }
                          )
                        }
                        color="primary"
                      />
                    }
                    label="Show Closed Tickets"
                  />
                </Toolbar>
              )
            }}
            options={{
              debounceInterval: 500,
              filtering: false,
              search: false
            }}
            data={query =>
              new Promise((resolve, reject) => {
                server
                  .post("ticket/my-tickets", {
                    page: query.page + 1,
                    showClosedTickets: this.state.showClosedTickets
                  })
                  .then(response => {
                    if (response.data.status === "success") {
                      resolve({
                        data: response.data.data.tickets.filter(
                          a =>
                            !this.state.selectedDepartment ||
                            a.department.id === this.state.selectedDepartment
                        ),
                        page: response.data.data.page - 1,
                        totalCount: response.data.data.pages * 10
                      });
                    } else {
                      this.props.showNotification("", response.data.message);

                      resolve({
                        data: [],
                        page: 0,
                        totalCount: 0
                      });
                    }
                  })
                  .catch(error => {
                    console.error(error);
                    this.props.showNotification("", "An error occured");

                    resolve({
                      data: [],
                      page: 0,
                      totalCount: 0
                    });
                  });
              })
            }
          />
        </Col>
      </>
    );
  }
}

const mapStateToProps = state => ({
  appContext: state.appContext
});

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNotifications(MyTickets));
