import React from "react";
import { CircularProgress } from "@material-ui/core";

export default () => (
  <div style={{ display: "table", width: '100%', textAlign: 'center', height: '100%' }}>
    <div style={{ display: "table-cell", height: '100%', verticalAlign: 'middle' }}>
      <CircularProgress />
    </div>
  </div>
);
