import React from 'react';
import Table from '../../../../shared/components/table';

export default (props) => (
  <Table
    data={props.planets}
    columns={[
      {title: 'Planet ID', field: 'planetID'},
      {title: 'Item ID', field: 'itemID'},
      {title: 'Planet Name', field: 'planetName'},
      {title: 'Solar Chakra', field: 'solarChakra'},
      {title: 'Triangle Position', field: 'trianglePosition'},
      {title: 'Level', field: 'level'},
      {title: 'Creation Date', field: 'CreationTime', render: rowData => new Date(rowData.CreationTime).toLocaleDateString()},
    ]}
    options={{
      maxBodyHeight: null,
      toolbar: false,
      paging: false
    }}
  />
)