import {
  LOGIN,
  LOGOUT,
} from '../actions/app-context-actions';

const initialState = {
  isLoggedIn: false,
  sessionData: {},
  settings: {}
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOGIN:
      return { ...state, isLoggedIn: true, ...action.payload };
    case LOGOUT:
      return { ...state, ...initialState };
    default:
      return state;
  }
}
