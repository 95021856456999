/* eslint-disable react/no-array-index-key */
import React from "react";
import PropTypes from "prop-types";
import TrendingDownIcon from "mdi-react/TrendingDownIcon";
import TrendingUpIcon from "mdi-react/TrendingUpIcon";
import TrendingNeutralIcon from "mdi-react/TrendingNeutralIcon";
import { Card, CardBody, Col, Progress } from "reactstrap";

const Icon = ({ count, prevCount }) => {
  if (count > prevCount) {
    return <TrendingUpIcon className="dashboard__trend-icon" />;
  } else if (count < prevCount) {
    return <TrendingDownIcon className="dashboard__trend-icon" />;
  } else {
    return <TrendingNeutralIcon className="dashboard__trend-icon" />;
  }
};

const calcPercentage = (count, prevCount) => {
  if (prevCount === 0) {
    return 0;
  } else {
    return Math.abs(Math.round((100 * (count - prevCount)) / prevCount));
  }
};

const ValueBox = (props) => {
  const percentage = calcPercentage(props.data.count, props.data.prevCount);
  return (
    <Col md={12} xl={3} lg={6} xs={12}>
      <Card>
        <CardBody className="dashboard__card-widget">
          <div className="mobile-app-widget">
            <div
              className={`mobile-app-widget__top-line mobile-app-widget__top-line--${props.color}`}
            >
              <p className="mobile-app-widget__total-stat">
                {props.data.count}
              </p>
              {props.showChange && (
                <Icon
                  count={props.data.count}
                  prevCount={props.data.prevCount}
                />
              )}
            </div>
            <div className="mobile-app-widget__title">
              <h5>{props.title}</h5>
            </div>
            {props.showChange && (
              <div
                className={`progress-wrap progress-wrap--small progress-wrap--${props.color}-gradient progress-wrap--label-top`}
              >
                <Progress value={percentage}>
                  <p className="progress__label">{percentage}%</p>
                </Progress>
              </div>
            )}
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default ValueBox;
