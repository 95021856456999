/* eslint-disable react/no-array-index-key */
import React from "react";
import Panel from "../../../../shared/components/Panel";
import server from "../../../../server";
import withNotifications from "../../../notification";
import * as consts from "../../../../consts";
import numeral from "numeral";
import { Tooltip } from "@material-ui/core";

class Currencies extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      data: {},
    };
  }

  componentDidMount() {
    this.refreshData();
  }

  refreshData = () => {
    this.setState({ isLoading: true }, () => {
      server
        .get("economy/currencies")
        .then((response) => this.setState({ data: response.data }))
        .catch((error) => {
          console.error(error);
          this.props.showNotification("", "An error occured");
        })
        .finally(() => {
          this.setState({ isLoading: false });
        });
    });
  };

  renderTopValue = (img, tooltip, value = 0) => {
    return (
      <Tooltip title={tooltip}>
        <div>
          <img src={img} style={{ padding: 2 }} />
          <div style={{ textAlign: "center", color: "#CCC", fontSize: 16, fontWeight: 500 }}>
            {numeral(value).format("0.00a")}
          </div>
        </div>
      </Tooltip>
    );
  };

  render() {
    return (
      <Panel
        lg={3}
        xl={3}
        md={12}
        title="Currencies"
        subhead=""
        isLoading={this.state.isLoading}
        onRefresh={this.refreshData}
      >
        <div className="dashboard__current-users">
          <div
            style={{
              display: "flex",
              width: "100%",
              marginTop: 24,
              alignItems: "center",
              color: "white",
            }}
          >
            {this.renderTopValue(
              consts.images.imgEnergy,
              "Total Energy",
              this.state.data.totalEnergy
            )}
            {this.renderTopValue(
              consts.images.imgSpirit,
              "Total Spirit",
              this.state.data.totalSpirit
            )}
            {this.renderTopValue(
              consts.images.imgDogma,
              "Total Dogma",
              this.state.data.totalDogma
            )}
          </div>
        </div>
      </Panel>
    );
  }
}

export default withNotifications(Currencies);
