import React from "react";
import { Button } from "@material-ui/core";

const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;

class ImageField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null
    };
  }

  onChange = e => {
    if (e.target.files && e.target.files.length > 0) {
      this.setState({ file: e.target.files[0], fileData: null }, () => {
        if (FileReader) {
          var fr = new FileReader();
          fr.onload = () => {
            this.setState({ fileData: fr.result }, this.onFileChange);
          };
          fr.readAsDataURL(this.state.file);
        } else {
          console.log("No FileReader support");
        }
      });
    }
  };

  onFileChange = () => {
    if(this.state.file) {
      this.props.onChange(this.state.file);
    }
    else {
      this.props.onChange(null);
    }
  }

  render() {
    const src = this.state.fileData || this.props.value || Ava;
    const size = this.props.size || 70;

    return (
      <div>
        <label
          htmlFor="raised-button-file"
          style={{ display: "inline-block", margin: "auto" }}
        >
          <img
            htmlFor="raised-button-file"
            style={{ width: size, height: size, borderRadius: 4 }}
            src={src}
          />
        </label>
        <input
          accept="image/*"
          style={{ width: 0, height: 0, overflow: "hidded" }}
          id="raised-button-file"
          type="file"
          onChange={this.onChange}
        />
        <Button
          color="primary"
          size="small"
          disabled={!this.state.fileData}
          variant="contained"
          style={{ display: "block", margin: '4px 0'}}
          onClick={() => this.setState({ file: null, fileData: null }, this.onFileChange)}
        >
          Remove
        </Button>
      </div>
    );
  }
}

export default ImageField;
