import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Layout from "../Layout/index";
import MainWrapper from "./MainWrapper";

import LogIn from "../LogIn/index";
import Dashboard from "../pages/dashboard";
import Players from "../pages/players";
import Sessions from "../pages/players/sessions";
import Strikes from "../pages/players/strikes";
import Patheons from "../pages/players/patheons";
import Actions from "../pages/actions";
import Rewards from "../pages/rewards";
import Economy from "../pages/economy";
import EconomyElements from "../pages/economy/elements";
import EconomyRates from "../pages/economy/rates";
import EconomyPricing from "../pages/economy/pricing";
import GalacticEvents from "../pages/galactic-events";
import Settings from "../pages/settings";
import PlayerProfile from "../pages/player-profile";
import { connect } from "react-redux";
import MyTickets from "../pages/support/my-tickets"; 
import NewTickets from "../pages/support/new-tickets";
import AllTickets from "../pages/support/all-tickets";

const Pages = () => (
  <Switch>
    <Route path="/pages/dashboard" component={Dashboard} />
    <Route path="/pages/players" component={() => <Players defaultSort="LastLogin" title="All Gods" showCreateOn={false}/>} />
    <Route path="/pages/new-gods" component={() => <Players defaultSort="CreatedOn" title="New Gods" showCreateOn={true}/>} />
    <Route path="/pages/pantheons" component={Patheons} />
    <Route path="/pages/sessions" component={Sessions} />
    <Route path="/pages/strikes" component={Strikes} />
    <Route path="/pages/actions" component={Actions} />
    <Route path="/pages/rewards" component={Rewards} />
    <Route path="/pages/economy" component={Economy} />
    <Route path="/pages/economy-elements" component={EconomyElements} />
    <Route path="/pages/economy-rates" component={EconomyRates} />
    <Route path="/pages/economy-pricing" component={EconomyPricing} />
    <Route path="/pages/galactic-events" component={GalacticEvents} />
    <Route path="/pages/settings" component={Settings} />
    <Route path="/pages/player-profile/:id" component={PlayerProfile} />
    <Route path="/pages/support/my-tickets" component={MyTickets} />
    <Route path="/pages/support/new-tickets" component={NewTickets} />
    <Route path="/pages/support/all-tickets" component={AllTickets} />
  </Switch>
);

const wrappedRoutes = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route path="/pages" component={Pages} />
    </div>
  </div>
);

const Router = () => (
  <MainWrapper>
    <main>
      <Switch>
        <Route exact path="/" component={LogIn} />
        <Route exact path="/log_in" component={LogIn} />
        <PrivateRoute path="/" component={wrappedRoutes} />
      </Switch>
    </main>
  </MainWrapper>
);

const mapStateToProps = state => ({ appContext: state.appContext });
const PrivateRoute = connect(mapStateToProps)(
  ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={props =>
        rest.appContext.isLoggedIn === true ? (
          <Component {...props} />
        ) : (
          <Redirect to="/log_in" />
        )
      }
    />
  )
);

export default Router;
