import {
  Popover,
  Typography,
  withStyles,
  Card,
  CardHeader,
  CardContent,
  Grid,
  Chip
} from "@material-ui/core";
import React from "react";

class TicketPopover extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      numberAnchorEl: null
    };
  }

  render() {
    const open = Boolean(this.state.numberAnchorEl);
    return (
      <>
        <Typography
          aria-owns={
            open
              ? "mouse-over-popover-" + this.props.ticket.ticketNumber
              : undefined
          }
          aria-haspopup="true"
          onMouseEnter={event =>
            this.setState({ numberAnchorEl: event.currentTarget })
          }
          onMouseLeave={() => this.setState({ numberAnchorEl: null })}
        >
          #{this.props.ticket.ticketNumber}
        </Typography>
        <Popover
          id={"mouse-over-popover-" + this.props.ticket.ticketNumber}
          className={this.props.classes.popover}
          classes={{
            paper: this.props.classes.paper
          }}
          open={open}
          anchorEl={this.state.numberAnchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          onClose={() => this.setState({ numberAnchorEl: null })}
          disableRestoreFocus
        >
          <Card elevation={0}>
            <CardHeader title={this.props.ticket.title} />
            <CardContent>
              <Typography variant="body1">
                {this.props.ticket.content}
              </Typography>
              <br />
              <Typography variant="caption">
                Author: {this.props.ticket.author.name}
              </Typography>

              <div style={{ marginTop: 10 }} />
              <Grid
                container
                style={{ backgroundColor: "#2f2f37", padding: 10 }}
              >
                <Grid item xs={3}>
                  <Typography variant="body1" style={{ paddingTop: 8 }}>
                    Status:
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Chip
                    label={this.props.ticket.closed ? "CLOSED" : "OPEN"}
                    color={this.props.ticket.closed ? "secondary" : "primary"}
                    style={{ width: 60 }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body1" style={{ paddingTop: 8 }}>
                    Priority:
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Chip
                    label={this.props.ticket.priority}
                    color="primary"
                    style={{ width: 60, textTransform: "capitalize" }}
                  />
                </Grid>
              </Grid>

              <Grid
                container
                style={{ backgroundColor: "#2f2f37", padding: 10 }}
              >
                <Grid item xs={3}>
                  <Typography variant="body1" style={{ paddingTop: 8 }}>
                    Owned:
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Chip
                    label={this.props.ticket.owner ? "YES" : "NO"}
                    color={this.props.ticket.owner ? "primary" : "secondary"}
                    style={{ width: 60 }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body1" style={{ paddingTop: 8 }}>
                    Comments:
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Chip
                    label={this.props.ticket.events.length}
                    color="secondary"
                    style={{ width: 60 }}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Popover>
      </>
    );
  }
}

const styles = theme => ({
  popover: {
    pointerEvents: "none"
  },
  paper: {}
});

export default withStyles(styles, { withTheme: true })(TicketPopover);
