import * as React from "react";
import { Col } from "reactstrap";
import Table from "../../../shared/components/table";
import server from "../../../server";
import PlayerInfo from "./player-info";
import EditPlayerDialog from "../../components/edit-player-dialog";
import { withRouter } from "react-router-dom";
import withNotifications from "../../notification";
import TimeAgo from "../../../shared/components/time-ago";
import PlayerColumn from "./player-column";
import { Paper } from "@material-ui/core";
import Progress from '../../../shared/components/progress'

const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;

class Sessions extends React.Component {
  tableRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      columns: [
        {
          title: "Id",
          field: "PlayerDataId",
          editable: "never",
          cellStyle: {
            paddingLeft: 5,
            paddingRight: 0,
            maxWidth: 60,
            width: 60
          },
          headerStyle: {
            paddingLeft: 5,
            paddingRight: 0,
            maxWidth: 60,
            width: 60
          }
        },
        {
          title: "Player",
          field: "LastLogin",
          defaultSort: "desc",
          render: player => <PlayerColumn player={player} />
        },
        {
          title: "Total Sessions",
          field: "TotalSessions"
        },
        {
          title: "Total Time (s)",
          field: "TotalTime"
        }
      ],
      data: []
    };
  }

  render() {
    return (
      <>
        <Col md={12}>
          <Table
            tableRef={this.tableRef}
            title="Sessions"
            columns={this.state.columns}
            options={{
              debounceInterval: 500,
              filtering: false
            }}
            actions={[
              {
                icon: "person",
                tooltip: "Full Profile",
                onClick: (e, player) =>
                  this.props.history.push(
                    "/pages/player-profile/" + player.PlayerDataId
                  )
              }
            ]}
            data={query =>
              new Promise((resolve, reject) => {
                const tmp = { ...query };
                if (!tmp.orderBy) {
                  tmp.orderBy = {
                    ...this.state.columns.find(c => c.field === "LastLogin")
                  };
                  tmp.orderDirection = "desc";
                }

                server
                  .post("players/sessions", tmp)
                  .then(response => {
                    resolve({
                      data: response.data.data,
                      page: query.page,
                      totalCount: response.data.totalCount
                    });
                  })
                  .catch(error => {
                    console.error(error);
                    this.props.showNotification("", "An error occured");

                    resolve({
                      data: [],
                      page: 0,
                      totalCount: 0
                    });
                  });
              })
            }
            detailPanel={[
              {
                tooltip: "Player Details",
                render: player => (
                  <div
                    style={{
                      backgroundColor: "#2f2f37",
                      padding: 10
                    }}
                  >
                    <SessionDetails userId={player.UserId} />
                  </div>
                )
              }
            ]}
            onRowClick={(event, rowData, togglePanel) => togglePanel()}
          />
        </Col>
      </>
    );
  }
}

class SessionDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      data: [],
      columns: [
        { title: "Session Id", field: "UserSessionId" },
        { title: "Start Time", field: "Session_Started", render: rowData => new Date(rowData.Session_Started).toLocaleString() },
        { title: "End Time", field: "Session_Ended", render: rowData => new Date(rowData.Session_Ended).toLocaleString() },
        { title: "Login Type", field: "Login_Type" },
        { title: "Login Result", field: "Login_Result" },
        { title: "OS", field: "Device_OS" },
        { title: "Network", field: "Device_Network" },
        { title: "Screen Resolution", field: "Device_ScreenResolution" },
        { title: "Language ", field: "Device_Language" },
        { title: "Signal Strength", field: "Device_SignalStrength" },
        { title: "GPS", field: "Device_GPS" },
        { title: "IP", field: "IP_address" },
        { title: "Version", field: "Game_Version" }
      ]
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true }, () => {
      server
        .get("players/session-details/" + this.props.userId)
        .then(response => {
          this.setState({
            isLoading: false,
            data: response.data
          });
        })
        .catch(error => {
          console.error(error);
          this.setState({ isLoading: false });
        });
    });
  }

  render() {
    if(this.state.isLoading) {
      return <Progress/>;
    }

    return (
      <Table
        columns={this.state.columns}
        options={{
          filtering: false,
          toolbar: false,
          pageSize: 5,
          pageSizeOptions: [5, 10, 20]
        }}
        data={this.state.data}
        components={{
          Container: props => (
            <Paper {...props} style={{ maxWidth: "100%", display: "grid" }} />
          )
        }}
      />
    );
  }
}

export default withRouter(withNotifications(Sessions));
