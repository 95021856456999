import * as React from "react";
import { Col } from "reactstrap";
import server from "../../../server";
import withNotifications from "../../notification";
import {
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Tooltip,
  Typography,
  Grid,
} from "@material-ui/core";
import Refresh from "@material-ui/icons/Refresh";
import Progress from "../../../shared/components/progress";
import TopItemsList from "./components/top-items-list";

class Economy extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      data: {},
    };
  }

  componentDidMount() {
    this.refreshData();
  }

  refreshData = () => {
    this.setState({ isLoading: true }, () => {
      server
        .get("economy/data")
        .then((response) => {
          this.setState({ data: response.data });
        })
        .catch((error) => {
          console.error(error);
          this.props.showNotification("", "An error occured");
        })
        .finally(() => {
          this.setState({ isLoading: false });
        });
    });
  };

  renderData = (title, value) => (
    <Grid container>
      <Grid item xs={6}>
        <Typography variant="subtitle1">{title}:</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
          {value}
        </Typography>
      </Grid>
    </Grid>
  );

  renderContent = () => {
    if (this.state.isLoading) {
      return <Progress />;
    }

    return (
      <Grid container>
        <Grid item xs={12} sm={4}>
          {this.renderData("Total Players", this.state.data.totalPlayers)}
          {this.renderData(
            "Active Players (24h)",
            this.state.data.totalActivePlayers
          )}
        </Grid>
        <Grid item xs={12} sm={4}>
          {this.renderData(
            "Total Energy",
            Math.trunc(this.state.data.totalEnergy)
          )}
          {this.renderData(
            "Total Spirit",
            Math.trunc(this.state.data.totalSpirit)
          )}
          {this.renderData(
            "Total Dogma",
            Math.trunc(this.state.data.totalDogma)
          )}
        </Grid>
        <Grid item xs={12} sm={4}>
          {this.renderData("Total Planets", this.state.data.totalPlanets)}
          {this.renderData("Total Temples", this.state.data.totalTemples)}
          {this.renderData(
            "Total Inhabitants",
            this.state.data.totalLevel * this.state.data.totalTemples
          )}
          {this.renderData(
            "Inhabitants",
            this.state.data.totalLevel * this.state.data.totalTemples
          )}
        </Grid>
      </Grid>
    );
  };

  render() {
    return (
      <>
        <div>
          <Col md={12}>
            <Card>
              <CardHeader
                title="Overview"
                action={
                  <Tooltip title="Refresh Data">
                    <IconButton onClick={this.refreshData}>
                      <Refresh />
                    </IconButton>
                  </Tooltip>
                }
              />
              <CardContent>{this.renderContent()}</CardContent>
            </Card>
          </Col>
        </div>
        <br />
        <Grid container style={{paddingBottom: 20}}>
          <Grid lg={6} md={6} sm={12} xs={12}>
            <TopItemsList
              serviceName="top-temple-items"
              title={"Top 20 Temple Items"}
            />
          </Grid>
          <Grid lg={6} md={6} sm={12} xs={12}>
            <TopItemsList
              serviceName="top-planet-items"
              title={"Top 20 Planet Items"}
            />
          </Grid>
        </Grid>
      </>
    );
  }
}

export default withNotifications(Economy);
