import { Card, CardContent, CardHeader, Divider, IconButton, CircularProgress,
  Tooltip, } from "@material-ui/core";
import * as React from "react";
import { withRouter } from "react-router-dom";
import { Col } from "reactstrap";
import server from "../../../server";
import SingleEditField from "../../components/single-edit-field";
import withNotifications from "../../notification";
import Refresh from "@material-ui/icons/Refresh";

class EconomyRates extends React.Component {
  tableRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      columns: [
        {
          title: "AdminConfigGalaxyId",
          field: "AdminConfigGalaxyId"
        },
        {
          title: "OfflineProductionMultiplier",
          field: "OfflineProductionMultiplier"
        },
        {
          title: "MaximumDepositMultiplierEnergy",
          field: "MaximumDepositMultiplierEnergy"
        },
        {
          title: "MaximumDepositMultiplierSpirit",
          field: "MaximumDepositMultiplierSpirit"
        }
      ],
      data: []
    };
  }

  componentDidMount() {
    this.refreshData();
  }

  refreshData = () => {
    this.setState({ isLoading: true }, () => {
      server
        .get("economy/rates")
        .then(response => this.setState({ data: response.data }))
        .catch(error => {
          console.error(error);
          this.props.showNotification("", "An error occured");
        })
        .finally(() => {
          this.setState({ isLoading: false });
        });
    });
  };

  onChange = (fieldName, value, callback) => {
    this.setState({ isLoading: true }, () => {
      server
        .post("economy/update-rate", {
          prms: {
            id: this.state.data[0].AdminConfigGalaxyId,
            fieldName,
            value
          },
          newData: this.state
        })
        .then(response => {
          this.props.showNotification(
            "",
            "Data updated successfully",
            "success"
          );
          this.refreshData();
        })
        .catch(error => {
          console.error(error);
          this.props.showNotification("", "An error occured");
        })
        .finally(() => {
          this.setState({ isLoading: false }, callback);
        });
    });
  };

  renderContent = () => {
    if (this.state.data && this.state.data.length > 0) {
      return (
        <>
         <SingleEditField
            label="Current Build IOS"
            value={this.state.data[0].CurrentBuild_iOS}          
          />
          <SingleEditField
            label="Current Build Android"
            value={this.state.data[0].CurrentBuild_Android}          
          />
          <SingleEditField
            label="Current Build Switch"
            value={this.state.data[0].CurrentBuild_Switch}          
          />
          <SingleEditField
            label="Game Mode"
            value={this.state.data[0].GameMode}
          />
          <SingleEditField
            label="Maintenance Mode"
            value={this.state.data[0].MaintenanceMode === "1"}
            onChange={(value, callback) => this.onChange("MaintenanceMode", value, callback)}
            isButtonsDisabled={this.state.isLoading}
            controlType="switch"
            editable
          />
          <SingleEditField
            label="Maintenance Message"
            value={this.state.data[0].MaintenanceMessage}
            onChange={(value, callback) => this.onChange("MaintenanceMessage", value, callback)}
            isButtonsDisabled={this.state.isLoading}
            editable
            textFieldWidth={240}
          />          
          <Divider style={{margin: '10px 0px'}}/>
          <SingleEditField            
            label="Offline Production Multiplier"
            value={this.state.data[0].OfflineProductionMultiplier}
            onChange={(value, callback) => this.onChange("OfflineProductionMultiplier", value, callback)}
            isButtonsDisabled={this.state.isLoading}
            editable            
          />
          <SingleEditField
            label="Maximum Deposit Multiplier Energy"
            value={this.state.data[0].MaximumDepositMultiplierEnergy}
            onChange={(value, callback) => this.onChange("MaximumDepositMultiplierEnergy", value, callback)}
            isButtonsDisabled={this.state.isLoading}
            editable
          />
          <SingleEditField
            label="Maximum Deposit Multiplier Spirit"
            value={this.state.data[0].MaximumDepositMultiplierSpirit}
            onChange={(value, callback) => this.onChange("MaximumDepositMultiplierSpirit", value, callback)}
            isButtonsDisabled={this.state.isLoading}
            editable
          />
          <SingleEditField
            label="Loyalty Dropping Rate"
            value={this.state.data[0].LoyaltyDroppingRate}
            onChange={(value, callback) => this.onChange("LoyaltyDroppingRate", value, callback)}
            isButtonsDisabled={this.state.isLoading}
            editable
          />
        </>
      );
    } else {
      return null;
    }
  };

  render() {
    return (
      <>
        <Col md={6} xs={12}>
          <Card>
            <CardHeader
              title="Rates"
              action={
                this.state.isLoading ? (
                  <CircularProgress size={48}/>
                ) : (
                  <Tooltip title="Refresh">
                    <IconButton onClick={this.refreshData}>
                      <Refresh/>
                    </IconButton>
                  </Tooltip>
                )
              }
            />
            <CardContent>{this.renderContent()}</CardContent>
          </Card>
        </Col>
      </>
    );
  }
}

export default withRouter(withNotifications(EconomyRates));
