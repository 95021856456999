import * as React from "react";
import {
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Checkbox,
  FormControl,
  InputLabel,
  FormControlLabel,
  Select,
  MenuItem
} from "@material-ui/core";
import { Button } from "reactstrap";
import server from "../../../../server";
import withNotifications from "../../../notification";
import Data from "./data";
import ImageField from "../../../components/image-field";
import imageUploader, { toImageUrl } from "../../../../util/image-uploader";

class EditPricingDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      pricingData: this.props.pricingData || {}
    };
  }

  setFieldOfPricing = (field, value, callback) => {
    const pricingData = { ...this.state.pricingData };
    pricingData[field] = value;
    this.setState({ pricingData }, callback);
  };

  renderTextField = (label, field, type = "text") => (
    <TextField
      label={label}
      value={this.state.pricingData[field]}
      onChange={e => this.setFieldOfPricing(field, e.target.value)}
      fullWidth
      style={{ marginBottom: 15 }}
      type={type}
    />
  );

  renderSelect = (label, field, options) => (
    <FormControl fullWidth style={{ marginBottom: 15 }}>
      <InputLabel htmlFor={label.replace(" ", "") + "-label-placeholder"}>
        {label}
      </InputLabel>
      <Select
        value={this.state.pricingData[field]}
        onChange={e => this.setFieldOfPricing(field, e.target.value)}
        inputProps={{
          name: label.replace(" ", ""),
          id: label.replace(" ", "") + "-label-placeholder"
        }}
        name="item-type"
      >
        {Object.keys(options).map(key => (
          <MenuItem value={key}>{options[key]}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  renderCheckbox = (label, field) => (
    <FormControlLabel
      style={{ marginTop: 10 }}
      control={
        <Checkbox
          checked={this.state.pricingData[field] === "1"}
          onChange={e =>
            this.setFieldOfPricing(field, e.target.checked ? "1" : "0")
          }
          color="primary"
        />
      }
      label={label}
    />
  );

  renderImageUploader = (label, field) => (
    <FormControl style={{ marginTop: 10 }} label={label}>
      <InputLabel htmlFor={label.replace(" ", "") + "-label-placeholder"}>
        {label}
      </InputLabel>
      <div style={{ marginBottom: 20, marginLeft: 65 }}>
        <ImageField
          value={this.state.pricingData[field] ? toImageUrl(this.state.pricingData[field]) : null}
          onChange={image => this.setState({ image })}
        />
      </div>
    </FormControl>
  );

  onSave = () => {
    this.setState({ isLoading: true }, () => {
      if (this.state.image) {
        imageUploader(this.state.image, 200 * 200)
          .then(url => this.setFieldOfPricing("Icon", url, this.saveData))
          .catch(error =>
            this.props.showNotification(
              "",
              "An error occured while image uploading"
            )
          )
          .finally(() => this.setState({ isLoading: false }));
      } else {
        this.saveData();
      }
    });
  };

  saveData = () => {
    const url = "economy/" + this.props.mode + "-pricing";
    this.setState({ isLoading: true }, () => {
      server
        .post(url, { pricing: this.state.pricingData })
        .then(response => {
          this.props.showNotification("", "Data saved successfully", "success");
          this.props.onClose(true);
        })
        .catch(error => {
          console.error(error);
          this.props.showNotification("", "An error occured");
        })
        .finally(() => {
          this.setState({ isLoading: false });
        });
    });
  };

  render() {
    return (
      <Dialog open fullWidth>
        <DialogTitle>{this.state.ItemName || "New Pricing"}</DialogTitle>
        <DialogContent>
          {this.renderImageUploader("Icon", "Icon")}
          {this.renderTextField("Item Id", "ItemId")}
          {this.renderTextField("Item Name", "ItemName")}
          {this.renderSelect("Item Type", "ItemType", Data.ItemTypeLookup)}
          {this.renderTextField("Description", "ItemDescription")}
          {this.renderSelect("Currency", "Currency", Data.CurrencyLookup)}
          {this.renderTextField("Amount", "Amount", "number")}
          {this.renderCheckbox("Enabled", "Enabled")}
        </DialogContent>
        <DialogActions>
          {this.state.isLoading && <CircularProgress />}
          <Button
            disabled={this.state.isLoading}
            onClick={() => this.props.onClose(false)}
          >
            Close
          </Button>
          <Button
            disabled={this.state.isLoading}
            color="primary"
            onClick={this.onSave}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withNotifications(EditPricingDialog);
