import { Typography } from "@material-ui/core";
import * as React from "react";
import { Col } from "reactstrap";
import server from "../../../../server";
import withNotifications from "../../../notification";
import Table from "../../../../shared/components/table";

class TopItemsList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      columns: [
        { title: "itemID", field: "itemID" },
        { title: "ItemName", field: "ItemName" },
        { title: "Count", field: "count" },
      ],
      data: [],
    };
  }

  componentDidMount() {
    this.refreshData();
  }

  refreshData = () => {
    this.setState({ isLoading: true }, () => {
      server
        .post("economy/" + this.props.serviceName, {
          top: this.props.top || 20,
        })
        .then((response) => {
          this.setState({ data: response.data });
        })
        .catch((error) => {
          console.error(error);
          this.props.showNotification("", "An error occured");
        })
        .finally(() => {
          this.setState({ isLoading: false });
        });
    });
  };

  render() {

    const maxBodyHeight = window.innerHeight < 720 ? 400 : 'calc(100vh - 420px)';

    return (
      <Col md={12} style={{paddingBottom: 12}}>
        <Table
          isLoading={this.state.isLoading}
          tableRef={this.tableRef}
          title={this.props.title}
          columns={this.state.columns}
          options={{
            debounceInterval: 500,
            filtering: false,
            search: false,
            paging: false,
            maxBodyHeight
          }}
          actions={[
            {
              icon: "refresh",
              tooltip: "Refresh Data",
              isFreeAction: true,
              onClick: this.refreshData,
            },
          ]}
          data={this.state.data}
        />
      </Col>
    );
  }
}

export default withNotifications(TopItemsList);
